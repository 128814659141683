<template>
  <div>
    <el-upload
      class="upload-demo"
      action
      :on-remove="handleRemove"
      multiple
      :show-file-list="isShowList"
      :file-list="loadFileList"
      :before-upload="beforeUpload"
      :http-request="upload"
    >
      <el-button>
        报告上传
        <i class="el-icon-upload2" />
      </el-button>
    </el-upload>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>上传中,请稍后</span>
    </el-dialog>
  </div>
</template>
<script>
import { uploadImportCompany } from "@/api/enterprise";
import { uploadImportProjectData } from "../api/numberProject";
import { uploadImportCase } from "../api/caseData";
import { uploadProduct } from "../api/product";
import { uploadScheme } from "../api/scheme";
import { addIndustry } from "../api/industry";
import { technology } from "../api/technology";
import { importActivity } from "../api/activity";
import { field } from "../api/field";
import { policyImportProject } from "../api/policy";
import { uploadInfo } from "@/api/paper";
import { importExcel } from "@/api/recruit.js";
import { importCommercial } from "@/api/commercial.js";
import { importClientEnterprise } from "@/api/customCompany";
import { apiUpload } from "../api/content";
import {
  importCountryStandard,
  importBehaviorStandard,
  importLocalStandard,
  importCompanyStandard,
  importTeamStandard,
  importInternationalStandard,
  importForeignStandard,
} from "@/api/standardKnowledge";
import {
  getKnowTechList,
  getIndustryList,
  getKnowFieldList,
  getDicRadioButton,
} from "@/api/common";
export default {
  props: {
    loadFileList: {
      type: Array,
    },
    isShowList: {
      type: Boolean,
      default: false,
    },
    moduleName: {
      type: String,
      default: "企业数据",
    },
  },
  data() {
    return {
      dialogVisible: false,
      fileArray: [],
    };
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  methods: {
    handleRemove(file, fileList) {},

    async upload(file) {
      this.dialogVisible = true;
      this.$emit("fileClicks");
      const form = new FormData();

      if (this.moduleName == "行业知识" || this.moduleName == "论文数据") {
        form.append("files", file.file);

        form.append("Authorization", this.Authorization);
      } else {
        form.append("file", file.file);

        form.append("Authorization", this.Authorization);
      }

      let res;

      switch (this.moduleName) {
        case "企业数据":
          res = await apiUpload(form);
          if (res.code == 200) {
            this.$message.success("文件上传成功");
          }
          break;

        case "项目数据":
          res = await uploadImportProjectData(form);
          break;

        case "产品数据":
          res = await uploadProduct(form);
          break;

        case "招聘数据":
          res = await importExcel(form);
          break;

        case "案例数据":
          res = await uploadImportCase(form);
          break;

        case "技术知识":
          res = await technology(form);
          break;

        case "领域知识":
          res = await field(form);
          break;

        case "活动数据":
          res = await importActivity(form);
          break;

        case "政策数据":
          res = await policyImportProject(form);
          break;

        case "方案拆解":
          res = await uploadScheme(form);
          break;

        case "客户企业":
          res = await importClientEnterprise(form);
          break;

        case "国家标准数据":
          res = await importCountryStandard(form);
          break;

        case "行业标准数据":
          res = await importBehaviorStandard(form);
          break;

        case "地方标准数据":
          res = await importLocalStandard(form);
          break;

        case "团体标准数据":
          res = await importTeamStandard(form);
          break;

        case "企业标准数据":
          res = await importCompanyStandard(form);
          break;

        case "国际标准数据":
          res = await importInternationalStandard(form);
          break;

        case "国外标准数据":
          res = await importForeignStandard(form);
          break;

        case "论文数据":
          res = await uploadInfo(form);
          break;

        case "政府数据":
          res = await importCommercial(form);
          break;

        case "行业知识":
          res = await addIndustry(form);
          this.$emit("fileClick");
          this.$confirm(res.msg, "提示", {
            dangerouslyUseHTMLString: true,
            customClass: "message-logout",
          })
            .then(() => {
              this.dialogVisible = false;
              this.$emit("fileClick");
            })
            .catch(() => {});
          const res1 = await getIndustryList();
          res1.data = JSON.parse(
            JSON.stringify(res1.data).replace(/industryName/g, "codeName")
          );
          sessionStorage.setItem("industry", JSON.stringify(res1.data));
          break;

        default:
          break;
      }

      if (res.code === 415 || res.code === 200) {
        this.dialogVisible = false;
        this.$confirm(res.msg, "提示", {
          dangerouslyUseHTMLString: true,
          customClass: "message-logout",
        })
          .then(() => {
            this.$emit("fileClick");
          })
          .catch(() => {});
      } else if (res.code == 500) {
        this.dialogVisible = false;
        this.$confirm(res.msg, "提示", {
          dangerouslyUseHTMLString: true,
          customClass: "message-logout",
        }).catch(() => {});
      }
    },

    beforeUpload(file) {
      let FileExt = file.name.replace(/.+\./, "");
      let flag = ["doc", "docx", "xls", "xlsx", "pdf"].includes(FileExt);
      // const isLt5M = file.size / 1024 / 1024 < 5
      if (!flag) this.$message.error("文件格式错误请重新上传!");
      // if (!isLt5M) this.$message.error('上传的文件大小不能超过5MB!')
      return flag;
    },
  },
};
</script>
<style lang="less" scoped>
.iconjr-icon-link {
  font-size: 12px;
}
.the-active {
  .el-button {
    color: #4da75b !important;
  }
  i {
    color: #4da75b !important;
  }
}
.jr-iconjr-icon-upload {
  font-size: 14px;
  // vertical-align: middle !important;
  // line-height: 34px;
}
</style>

<style lang="less">
.message-logout {
  .el-message-box__message p {
    margin: 0;
    line-height: 24px;
    max-height: 300px !important;
    overflow: auto;
  }
  .el-message-box__container {
    max-height: 300px !important;
    overflow: auto;
  }
}
</style>

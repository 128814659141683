<template>
  <div class="projectData">
    <el-form :model="queryInfo" class="ly-header__bg" label-width="100px">
      <el-form-item label="所在地区：">
        <cascader-area
          :country="queryInfo.country"
          :province="queryInfo.province"
          :city="queryInfo.city"
          @searchCountry="searchCountry"
          @searchProvince="searchProvince"
          @searchCity="searchCity"
        />
      </el-form-item>
      <el-form-item label="成立日期：">
        <dic-radio-button
          :code.sync="queryInfo.establishmentDate"
          type-code="039"
          query-name="establishmentDate"
          name="成立日期"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="行业类别：">
        <dic-tag-button
          :code.sync="queryInfo.industryCategoryListName"
          query-name="industryCategoryListName"
          name="行业类别"
          tag="行业"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="专精特新：">
        <dic-radio-button
          :code.sync="queryInfo.companyLevel"
          type-code="040"
          query-name="companyLevel"
          name="专精特新"
          @onChange="onChange"
        />
      </el-form-item>
      <!-- <el-form-item label="领域类别：">
        <dic-tag-button
          :code.sync="queryInfo.fieldName"
          query-name="fieldName"
          name="领域类别"
          tag="领域"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="技术类别：">
        <dic-tag-button
          :code.sync="queryInfo.technologyTypeName"
          query-name="technologyTypeName"
          name="技术类别"
          tag="技术"
          @onChange="onChange"
        />
      </el-form-item> -->
      <el-form-item label="筛选条件：" class="filterInfo">
        <el-tag
          closable
          v-for="(item, index) in tags"
          :key="index + item.name"
          @close="closeTag(index, item.tagName)"
        >
          {{ item.name }}：{{
            queryInfo[item.tagName] ? queryInfo[item.tagName] : "不限"
          }}
          <span
            class="circle"
            v-if="item.hasOwnProperty('valLength') && item.valLength > 0"
            >+{{ item.valLength }}</span
          >
        </el-tag>
      </el-form-item>
    </el-form>

    <div class="ly-container__bg">
      <div class="ly_operation">
        <div class="left">
          <el-button type="primary" icon="jr-iconjr-icon-add" @click="addItem"
            >添加</el-button
          >
          <import-data moduleName="客户企业" @fileClick="fileClick" />
          <Import-data-has moduleName="客户企业" @fileClick="fileClick" />
          <el-button @click="multiDelete">
            批量删除
            <i class="jr-iconjr-icon-delect" />
          </el-button>
          <popover @popoverClick="popoverClick" />
          <div class="upload_time">
            <div class="caretTime" @click="uploadTime" style="color: #606266">
              按创建时间
            </div>
            <i
              class="el-icon-caret-top"
              :class="[
                queryInfo.columnName == 'create_time' && queryInfo.order == 0
                  ? 'isActiveTime1'
                  : 'iconColor',
              ]"
            />
            <i
              class="el-icon-caret-bottom"
              :class="[
                queryInfo.columnName == 'create_time' && queryInfo.order == 1
                  ? 'isActiveTime1'
                  : 'iconColor',
              ]"
            />
          </div>
        </div>
        <el-input
          placeholder="根据企业全称、曾用名进行搜索"
          v-model="queryInfo.queryConditions"
          class="lager-input"
          @change="search"
        >
          <template slot="append">
            <div @click="search">搜索</div>
          </template>
        </el-input>
      </div>
      <el-table
        class="ly_table"
        :data="projectTable"
        :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
        @selection-change="handleSelectionChange"
        :default-sort="{ prop: 'date', order: 'descending' }"
        @sort-change="sortChange"
      >
        <el-table-column type="selection" width="55" align="center" />
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset="" />
        </template>
        <el-table-column label="序号" align="center" width="80">
          <template v-slot="scope">
            <span
              >{{
                (queryInfo.pageNum - 1) * queryInfo.pageSize +
                (scope.$index + 1)
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="dataIntegrity"
          label="数据完整度"
          sortable="custom"
          align="center"
          width="120"
        >
          <template v-slot="{ row }">
            <div @click="editItem(row)" style="cursor: pointer">
              <el-progress
                type="circle"
                :percentage="row.dataIntegrity"
                :stroke-width="12"
              ></el-progress>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="companyLogoLook"
          label="企业logo"
          align="center"
          width="100"
        >
          <template v-slot="{ row }">
            <div
              @click="editItem(row)"
              style="cursor: pointer"
              v-if="row.companyLogoLook"
            >
              <el-image :src="row.companyLogoLook" class="headerImg" />
            </div>
            <div @click="editItem(row)" style="cursor: pointer" v-else>
              <el-image
                :src="require('../../assets/img/pro.png')"
                class="headerImg"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="companyFullName"
          label="企业全称"
          align="center"
          width="230"
        >
          <template v-slot="{ row }">
            <div @click="editItem(row)" style="cursor: pointer">
              <span>{{ row.companyFullName }}</span>
            </div>
          </template>
        </el-table-column>
        <!-- 发布日期未确定 -->
        <el-table-column
          prop="companyBriefIntroduction"
          label="简介"
          align="center"
          width="200"
        >
          <template slot-scope="scope">
            <div>
              <el-popover
                placement="right"
                width="300"
                trigger="hover"
                :content="scope.row.companyBriefIntroduction"
              >
                <div slot="reference">
                  <div
                    v-if="
                      scope.row.companyBriefIntroduction !== null &&
                      scope.row.postAgency !== ''
                    "
                  >
                    {{ scope.row.companyBriefIntroduction.substring(0, 20) }}
                    <span v-if="scope.row.companyBriefIntroduction.length > 20"
                      >...</span
                    >
                  </div>
                </div>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="companyLevel" label="专精特新" align="center">
        </el-table-column> -->
        <el-table-column
          prop="legalRepresentative"
          label="法定代表人"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="industryCategory"
          label="行业类别"
          align="center"
        >
          <template slot-scope="scope">
            <div
              v-if="
                scope.row.industryCategory !== null &&
                scope.row.industryCategory !== ''
              "
            >
              {{ scope.row.industryCategory.substring(0, 20) }}
              <span v-if="scope.row.industryCategory.length > 20">...</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="所在地区" align="center">
          <template v-slot="{ row }">
            {{ row.country ? row.country + "-" : "" }}
            {{ row.province ? row.province + "-" : "" }}
            {{ row.city ? row.city : "" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="createName"
          label="创建人"
          align="center"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
          width="120"
          :formatter="formatTime"
        ></el-table-column>
        <el-table-column label="操作" width="140" align="center">
          <template v-slot="{ row }">
            <!-- <button-table
              content="下载"
              @buttonClick="downloadClick(row)"
              icon="jr-iconjr-icon-download"
              :class="hasData"
            /> -->
            <button-table
              @buttonClick="editItem(row)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteItem(row)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50, 100, 200]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import Popover from "@/components/Popover.vue";
import CascaderArea from "@/components/CascaderArea.vue";
// import DataTime from '@/components/DataTime.vue'
import ImportData from "@/components/ImportData.vue";
import ImportDataHas from "@/components/ImportDataHas.vue";
import DicTagButton from "@/components/DicTagButton.vue";
import DicRadioButton from "@/components/DicRadioButton.vue";
import ButtonTable from "@/components/ButtonTable.vue";
import { pagination } from "@/mixins/pagination";
import {
  getClientEnterpriseServiceList,
  deleteByIdList,
} from "@/api/customCompany";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import { updateProjectData } from "@/api/numberProject";
import { exportData } from "@/utils/tool";
import { downloadFile } from "@/api/common";
export default {
  name: "ProjectData",

  mixins: [pagination],

  components: {
    ImportData,
    DicTagButton,
    DicRadioButton,
    ButtonTable,
    CascaderArea,
    Popover,
    DicCheckboxGroup,
    ImportDataHas,
  },

  data() {
    return {
      // 查询
      queryInfo: {
        columnName: "update_time",
        pageSize: 10,
        pageNum: 1,
        queryConditions: "", // 搜索查询条件
        year: null, // 年
        month: null, // 月
        day: null, // 日
        order: 1, // 0正序 1倒叙
        idList: [],
      },
      projectTable: [], // 表格数据
      tags: [
        {
          name: "成立日期",
          tagName: "establishmentDate",
        },
        {
          name: "行业类别",
          tagName: "industryCategoryListName",
          valLength: 0,
        },
        {
          name: "专精特新",
          tagName: "companyLevel",
        },
      ],
      delete: {
        id: [],
        multiId: [],
      },
      dialogType: "行业类别",
      dialogVisible: false,
      editTableObj: {
        projectIndustryType: [], // 行业
        industryChildrenId: [], // 领域类别二级标签
        projectFieldType: [], // 领域
        fieldChildrenId: [], // 领域类别二级标签
      },
      valueI: 0,
    };
  },
  created() {
    this.search();
  },
  computed: {
    hasData() {
      return 1;
    },
  },
  methods: {
    fileClick() {
      this.search();
    },
    async search() {
      const res = await getClientEnterpriseServiceList(this.queryInfo);

      if (res.code === 200) {
        this.projectTable = res.data.list;
        this.total = res.data.total;
        // dataIntegrity存在
        if (this.projectTable.dataIntegrity) {
          this.projectTable.forEach((el) => {
            if (el.dataIntegrity) {
              el.dataIntegrity = Number(el.dataIntegrity.slice(0, -1));
            } else {
              el.dataIntegrity = 0;
            }
          });
        }
      }

      this.total = res.data.total;
    },
    searchCountry(val) {
      this.queryInfo.country = val;
      this.search();
    },
    searchProvince(val) {
      this.queryInfo.province = val;
      this.search();
    },
    searchCity(val) {
      if (val != "北京" && val != "天津" && val != "上海" && val != "重庆") {
        this.queryInfo.city = val;
        this.search();
      }
    },
    // 弹窗修改
    async saveItem() {
      if (this.editTableObj.fieldChildrenId) {
        this.editTableObj.projectFieldType =
          this.editTableObj.projectFieldType.concat(
            this.editTableObj.fieldChildrenId
          );
      }

      if (this.editTableObj.industryChildrenId) {
        this.editTableObj.projectIndustryType =
          this.editTableObj.projectIndustryType.concat(
            this.editTableObj.industryChildrenId
          );
      }

      this.editTableObj.projectIndustryType = [
        ...new Set(this.editTableObj.projectIndustryType),
      ];
      this.editTableObj.projectFieldType = [
        ...new Set(this.editTableObj.projectFieldType),
      ];

      const res = await updateProjectData(this.editTableObj);

      if (res.code === 200) {
        this.$message.success("编辑成功");
      }

      this.dialogVisible = false;

      this.search();
    },
    // 弹窗关闭
    handleClose() {
      this.dialogVisible = false;

      this.$refs.checkboxProject.clear();
    },
    updateCode(val, tag) {
      // this.nextTick(() => {
      switch (tag) {
        case "领域":
          this.editTableObj.fieldChildrenId = val;
          break;

        case "行业":
          this.editTableObj.industryChildrenId = val;
          break;

        default:
          break;
      }
      // })
    },
    async uploadTime() {
      switch (this.valueI) {
        case 0:
          this.queryInfo.columnName = "create_time";
          this.queryInfo.order = 1;
          this.valueI++;
          break;
        case 1:
          this.queryInfo.columnName = "create_time";
          this.queryInfo.order = 0;
          this.valueI++;
          break;
        case 2:
          this.queryInfo.columnName = "update_time";
          this.queryInfo.order = 1;
          this.valueI = 0;
          break;
      }
      this.search();
    },
    dateClick(obj) {
      if (obj.year == "0") {
        this.queryInfo.year = "";
        this.queryInfo.month = "";
        this.queryInfo.day = "";
        this.search();
      } else if (obj.month == "0") {
        this.queryInfo.year = obj.year + "";
        this.queryInfo.month = "";
        this.queryInfo.day = "";
        this.search();
      } else if (obj.day == "0") {
        this.queryInfo.year = obj.year + "";
        this.queryInfo.month = obj.month + "";
        this.queryInfo.day = "";
        this.search();
      } else {
        this.queryInfo.year = obj.year + "";
        this.queryInfo.month = obj.month + "";
        this.queryInfo.day = obj.day + "";
        this.search();
      }
    },

    // 字符串格式化成数组
    formatString(val) {
      if (!val) {
        return [];
      } else if (!val.length) {
        return val;
      } else {
        return val.split(",");
      }
    },

    // 查询条件更变时el-tag跟着改变
    onChange(val, queryName, name, checkboxVal) {
      let childrenVal = [];

      if (!this.tags.some((item) => item.tagName === queryName)) {
        if (name === "行业类别" || name === "领域类别" || name === "技术类别") {
          this.tags.push({
            name,
            tagName: queryName,
            valLength: 0,
          });
        } else {
          this.tags.push({
            name,
            tagName: queryName,
          });
        }
      }

      // 拿到name在tags的下标
      let result;
      result = this.tags.findIndex((el) => {
        return el.name === name;
      });

      switch (name) {
        case "行业类别":
          this.queryInfo.industryCategoryList = [val];

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;

            childrenVal = checkboxVal;

            this.queryInfo.industryCategoryList =
              this.queryInfo.industryCategoryList.concat(childrenVal);
          } else {
            this.tags[result].valLength = 0;
          }

          break;

        case "领域类别":
          this.queryInfo.field = [val];

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;

            childrenVal = checkboxVal;

            this.queryInfo.field = this.queryInfo.field.concat(childrenVal);
          } else {
            this.tags[result].valLength = 0;
          }
          break;

        case "技术类别":
          this.queryInfo.technologyType = [val];

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;

            childrenVal = checkboxVal;

            this.queryInfo.technologyType =
              this.queryInfo.technologyType.concat(childrenVal);
          } else {
            this.tags[result].valLength = 0;
          }

          break;

        default:
          break;
      }

      this.search();
    },
    // tag标签关闭
    closeTag(index, name) {
      this.tags.splice(index, 1);

      switch (name) {
        case "industryCategoryList":
          this.queryInfo.industryCategoryListName = "";
          this.queryInfo.industryCategoryList = [];
          break;
        case "technologyType":
          this.queryInfo.technologyTypeName = "";
          this.queryInfo.technologyType = [];
          break;
        case "field":
          this.queryInfo.fieldName = "";
          this.queryInfo.field = [];
          break;
        default:
          this.queryInfo[name] = "";
          break;
      }
      this.search();
    },
    // 编辑
    editItem(row) {
      // this.$router.push({
      //   name: "PolicyDataInfo",
      //   params: {
      //     personType: 1,
      //     id: row.id,
      //   },
      // });
      // let text = this.$router.resolve({
      //   path: "/customerCompany/info",
      //   query: {
      //     enterpriseType: 1,
      //     id: row.id
      //   }
      // });
      // window.open(text.href, "_blank");
      let text = this.$router.resolve({
        path: "/customerCompany/customerMain",
        query: {
          companyCode: row.companyCode,
          enterpriseType: 1,
          id: row.id,
          headTabActive: 0,
          type: 1,
        },
      });
      window.open(text.href, "_blank");
    },
    // 添加按钮
    addItem() {
      this.$router.push({
        name: "customerMain",
        query: {
          personType: 0,
          headTabActive: 0,
          type: 1,
        },
      });
    },
    // 时间
    formatTime(row, column) {
      const date = new Date(row[column.property]);
      return (
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      );
    },
    handleSelectionChange(val) {
      this.delete.multiId = val.map((item) => {
        return item.id;
      });
      this.queryInfo.idList = val.map((item) => {
        return item.id;
      });
    },
    _deleteProject(params) {
      this.$confirm("确认删除吗 ?", "提示")
        .then(async () => {
          const res = await deleteByIdList(params);

          if (res.code === 200) {
            this.$message.success("删除成功");
            this.search();
            // this.delete.id = []
          }
        })
        .catch(() => {});
    },
    deleteItem(row) {
      this.delete.id.push(row.id);
      this._deleteProject(this.delete.id);
    },
    multiDelete() {
      if (this.delete.multiId.length > 0) {
        this._deleteProject(this.delete.multiId);
      } else {
        this.$message.warning("请选择至少一条数据");
      }
    },
    sortChange(column) {
      this.pageNum = 1;
      if (column.prop === "dataIntegrity") {
        this.queryInfo.columnName = "data_integrity";
        if (column.order === "ascending") {
          this.queryInfo.order = 0;
        } else if (column.order === "descending") {
          this.queryInfo.order = 1;
        } else {
          this.queryInfo.order = 1;
          this.queryInfo.columnName = "update_time";
        }
      }
      this.search();
    },
    // 批量导出
    popoverClick(val) {
      if (val === "all") {
        Object.assign(this.queryInfo, { idList: [], size: "" });
      } else if (val == 0 && this.queryInfo.idList.length) {
        Object.assign(this.queryInfo, {
          idList: this.delete.multiId,
        });
      } else {
        Object.assign(this.queryInfo, { size: val });
      }

      // 刘议改后的路径
      exportData(
        this.queryInfo, // 后台需要的参数
        "/clientEnterprise/v1/exportExcel", // 路径
        "application/msexcel", // 下载的文件类型
        this.search, // 下载完之后调查询方法
        "客户企业数据.xls"
      );
      // 开始的路径
      // exportData(
      //   this.queryInfo, // 后台需要的参数
      //   "/clientEnterprise/exportExcel", // 路径
      //   "application/msexcel", // 下载的文件类型
      //   this.search, // 下载完之后调查询方法
      //   "客户企业数据.xls"
      // );
    },
    // 目的是从某个 URL 下载一个或多个文件，并将这些文件合并（或尝试下载为 ZIP 文件）
    // 这是一个异步函数，意味着它内部可以使用 await 关键字来等待 Promise 的解析。此函数接受一个 row 参数，该参数可能是一个对象，其中包含一个 productCode 属性
    //下载产品附件
    async downloadClick(row) {
      // 使用 await 等待 downloadFile 函数的结果。这个函数可能是一个 API 调用，用于从与 row.productCode 相关的 URL 获取文件或文件列表
      const res = await downloadFile({ urlStr: row.productCode });
      if (res.code == 200) {
        // 这里，它首先从响应中获取文件列表 (res.data)，
        // 然后遍历这个列表并将每个文件的 productFileKey（可能是文件的唯一标识符或 URL）添加到 arr 数组中
        const arr = [];
        this.multipleFile = res.data;
        this.multipleFile.forEach((el) => {
          arr.push(el.productFileKey);
        });
        // 准备 ZIP 文件的下载请求 使用逗号将 arr 数组中的所有 productFileKey 连接成一个字符串
        const paramsurl = arr.join(",");
        // const URL = 'http://192.168.0.6:8085/'
        // 使用 axios 发送 POST 请求到 ${URL}/public/downZip，其中 URL 似乎是一个未在此代码段中定义的变量。
        // 它传递了一个 params 对象，其中包含了一个 urlList 参数，其值为前面创建的 paramsurl 字符串。此请求还指定了 responseType 为 "blob"，
        // 意味着它期望接收一个二进制大对象（Blob），通常用于文件下载
        axios({
          method: "POST",
          url: `${URL}/public/downZip`,
          params: { urlList: paramsurl },
          responseType: "blob",
          headers: {
            // 设置请求头
            Authorization: this.Authorization,
          },
        })
          // 处理 ZIP 文件的下载响应
          .then((res) => {
            //  创建一个新的 Blob 对象，该对象表示下载的 ZIP 文件。
            // * 创建一个隐藏的 `<a>` 标签，设置其 `href` 属性为 Blob 对象的 URL，并设置其 `download` 属性为文件名（例如 "数据系统产品附件.zip"）。
            // * 触发 `<a>` 标签的点击事件，从而开始文件下载。
            // * 释放创建的 URL 对象和 `<a>` 标签，以避免内存泄漏
            let blob = new Blob([res.data], {
              type: "application/zip",
            });
            let href = window.URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
            const a = document.createElement("a"); //创建a标签
            a.style.display = "none";
            a.href = href; // 指定下载'链接
            a.download = "数据系统产品附件.zip"; //指定下载文件名
            a.click(); //触发下载
            URL.revokeObjectURL(a.href); //释放URL对象
            document.body.removeChild(a); //释放标签
          })
          .catch((err) => {
            //this.$message.error('文件下载失败')
            console.log(err)
          });
        // 这段代码的主要目的是：
        // 从与 row.productCode 相关的 URL 获取文件列表。
        // 使用这些文件的 productFileKey 向另一个 URL 发送请求以获取 ZIP 文件。
        // 下载这个 ZIP 文件
      }
    },
  },
};
</script>
<style lang="less" scoped>
.projectData {
  /deep/ .el-input-group__append {
    background: #1e9fff;
    color: #fff;
    border: 1px solid #1e9fff;
  }
  .caretTime:hover {
    cursor: pointer;
  }
}
.isActiveTime1 {
  color: #1e9fff !important;
}
.iconColor {
  color: #c0c4cc !important;
}
/deep/ .filterInfo .el-form-item__content {
  padding: 0;
}
</style>
